import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { BuyerService } from '../../_services/buyer.service';
import { Buyer } from '../../_models/buyer';

import { ExpertSearchForm } from '../../_models/expertSearchForm';
import { ExpertSearchService } from '../../_services/expert-search.service';

import { ExpertService } from '../../_services/expert.service'
import { ExpertSearchResult } from '../../_models/expertSearchResult';
import { ExpertMatch } from '../../_models/expertMatch';

@Component({
  selector: 'app-experts-web-result',
  templateUrl: './experts-web-result.component.html',
  styleUrls: ['./experts-web-result.component.css']
})
export class ExpertsWebResultComponent implements OnInit, AfterViewInit {

  buyer : Buyer;
  expertSearchForm : ExpertSearchForm;
  //Temporary critère, to be replaced by a function which retruns the SupplierMatch in the right service.
  resultFound : boolean;

  //Result display static
  displayedColumns: string[] = ['id', 'regions', 'industries', 'coreCompetency', 'matchingRate', 'actions'];
  dataSource : MatTableDataSource<ExpertMatch>;
  dataNumber : number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource, "afterviewinit");
  }

  constructor(
    private buyerService: BuyerService,
    private expertSearchService: ExpertSearchService,
    private expertService : ExpertService
  ) { }

  ngOnInit(): void {
    this.buyer = this.buyerService.buyerValue;
    var expertSearchResultValue =  this.expertSearchService.expertSearchResultValue;
    console.info("expert-web-reslut,ngOnInit, expert result value", expertSearchResultValue);
    if (expertSearchResultValue) {
      this.dataSource = new MatTableDataSource<ExpertMatch>(expertSearchResultValue.expertMatchs);
      console.log(this.dataSource, "init");
      this.dataNumber = this.dataSource.data.length;
      if (this.dataNumber > 0) {
        this.resultFound = true;
      } else {
        this.resultFound = false;
      }
    } else {
      this.resultFound = false;
    }
    
  }
}
