<h5 mat-dialog-title>Decline</h5>

<mat-dialog-content [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill" style="width: 300px;">
    <mat-label for="rejection_reason">Specific reason for rejection</mat-label>
    <mat-select formControlName="rejection_reason" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.controls.rejection_reason.errors }">
      <mat-option *ngFor="let rejectionReasonKey of rejectionReasonKeys" [value]="rejectionReasonKey">
        {{rejectionReason[rejectionReasonKey]}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="row">
    <div class="col-6">
      <div class="button button--filled right-10" (click)="onSubmit()" type="submit">Submit</div>
    </div>
    <div class="col-6">
      <div class="button button--out" (click)="cancel()" type="submit">Cancel</div>
    </div>
  </div>
</mat-dialog-actions>
