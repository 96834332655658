<main class="main mobile-top">
  <section class="section blog">
    <div class="container">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <h1>General Terms</h1>
          <h2>CONDITIONS GÉNÉRALES D’UTILISATION DE LA PLATEFORME</h2>
          <p>&nbsp;</p>
          <h3>ARTICLE 1.	OBJET</h3>
          <p>Les présentes conditions générales d’utilisation (les « Conditions Générales d’Utilisation ») détaillent les droits et obligations de la société ASKAFOX, société par actions simplifiée de droit français, au capital de 30 000 €, dont le siège social est situé  43, rue Waldeck Rousseau, 69006 Lyon, immatriculée au Registre du Commerce et des Sociétés de Lyon sous le numéro R.C.S 902 201 300 (ci-après la « Société ») et de tout utilisateur de la plateforme logicielle ASKAFOX éditée et fournie par la Société, accessible en mode Saas, et des fonctionnalités et services qu’elle propose (ci-après la « Plateforme »).
            La Plateforme s’appuie notamment sur un algorithme propriétaire permettant la recherche par un acheteur de fournisseurs à partir de critères de recherche et/ou la mise en relation entre acheteurs et experts dans le domaine du conseil, lorsque les besoins d’un acheteur coïncident avec les besoins d’un expert.
            </p>
          <p>La Plateforme s’appuie notamment sur un algorithme propriétaire permettant la recherche par un acheteur de fournisseurs à partir de critères de recherche et/ou la mise en relation entre acheteurs et experts dans le domaine du conseil, lorsque les besoins d’un acheteur coïncident avec les besoins d’un expert.</p>
          <h3>ARTICLE 2.	DÉFINITIONS</h3>
          <p>Les termes suivants utilisés avec une majuscule ont la signification suivante, étant précisé que les termes au pluriel peuvent s‘entendre au singulier et réciproquement :</p>
          <p>« Acheteur » « Vous » « Vos » « Votre » : désigne tout acheteur personne physique agissant au nom et pour le compte d’un professionnel au sens du Code de la consommation et souhaitant s’inscrire sur la Plateforme aux fins de bénéficier des services et fonctionnalités qu’elle propose ;</p>
          <p>« Compte » : désigne le compte ouvert gratuitement au nom d’un Utilisateur lors de son inscription sur le Site, lui permettant d’utiliser la Plateforme et d’accéder aux services et fonctionnalités qu’elle propose ;</p>
          <p>« Conditions Générales d’Utilisation »  : a le sens défini à l’Article 1 ;</p>
          <p>« Données » : désignent les Données à Caractère Personnel et tout autre donnée requise pour ouvrir le Compte ;</p>
          <p>« Données à Caractère Personnel » : désigne toute donnée identifiant directement ou indirectement un Utilisateur, au sens du Règlement ;</p>
          <p>« Expert » « Vous » « Vos » « Votre » : désigne tout expert agissant au nom et pour le compte d’un professionnel au sens du Code de la consommation dans le domaine du conseil et souhaitant s’inscrire sur la Plateforme aux fins de bénéficier des services et fonctionnalités qu’elle propose ;</p>
          <p>« Plateforme » : a le sens défini à l’Article 1 ;</p>
          <p>« Politique de Confidentialité » : désigne la politique de confidentialité de la Société applicable à toute collecte de Données à Caractère Personnel réalisée via la Plateforme, et accessible par tout Utilisateur via l’URL suivant : <a routerLink="/information/privacy-terms">Privacy policy</a>;</p>
          <p>« Règlement » : désigne le Règlement n°2016-679 du 27 avril 2016 relatif à la protection des données à caractère personnel ;</p>
          <p>« Société » : a le sens défini à l’Article 1 ;</p>
          <p>« Site » : désigne le site internet https://www.askafox.com édité et fourni par la Société, à partir duquel est accessible la Plateforme ;</p>
          <p>« Utilisateur » « Vous » « Vos » « Votre » : désigne indistinctement l’ensemble des utilisateurs de la Plateforme à savoir les Acheteurs et les Experts.</p>
          <h3>ARTICLE 3.	CRÉATION DE VOTRE COMPTE - ACCEPTATION DES CONDITIONS GÉNÉRALES D’UTILISATION - CHARTE ÉTHIQUE – SUPPRESSION DE VOTRE COMPTE</h3>
          <p>&nbsp;</p>
          <h4>3.1.	CRÉATION DE VOTRE COMPTE</h4>
          <p>Vous intervenez comme Utilisateur de la Plateforme.</p>
          <p>Afin de pouvoir utiliser la Plateforme, Vous devez créer Votre Compte sur le Site en fournissant Vos Données à l’aide du formulaire d’inscription. Vous devez fournir un courriel d’identification et un mot de passe de connexion. Vous êtes seul responsable du maintien de confidentialité de Vos identifiants de connexion.</p>
          <p>Vous Vous engagez à transmettre des Données exactes, et à les mettre régulièrement à jour. Ces Données restent confidentielles</p>
          <p>En cas de perte ou d’oubli du mot de passe de Votre Compte, Vous pourrez à tout moment réinitialiser Votre mot de passe de connexion en cliquant sur l’onglet « Password forgotten ? » et en suivant les instructions de réinitialisation communiquées par la Société.</p>
          <h4>3.2.	ACCEPTATION DES CONDITIONS GÉNÉRALES D’UTILISATION - CHARTE ÉTHIQUE</h4>
          <p>Toute création de Votre Compte est subordonnée à l’acceptation expresse et univoque des Conditions Générales d’Utilisation par le cochage d’une case spécifique disponible lors de la création de Votre Compte.</p>
          <p>Tout Compte est strictement personnel et non cessible. Vous Vous engagez à ne créer et/ou à n’utiliser qu’un seul Compte. La création d’un Compte sous l’identité d’un tiers ou sous une fausse identité est formellement prohibée et est susceptible d’entraîner la suspension ou la suppression immédiate de Votre Compte ou le blocage de l’accès à la Plateforme. </p>
          <p>L’usage de la Plateforme reste sous Votre seule responsabilité.</p>
          <p>En cas de désaccord avec le contenu des présentes, vous ne pourrez pas accéder à la Plateforme. </p>
          <p>Les présentes Conditions Générales d’Utilisation peuvent être modifiées à tout moment. Dans un tel cas, pour que les nouvelles Conditions Générales d’Utilisation Vous soient applicables, Vous devez réitérer Votre acceptation lors d’une prochaine connexion. A défaut, tout accès à la Plateforme sera suspendu. </p>
          <p>Du fait de l’acceptation des Conditions Générales d’Utilisation, Vous allez Vous voir soumettre par la Société sur la Plateforme une charte éthique que Vous devez accepter si Vous souhaitez continuer à accéder à votre Compte. À défaut, tout accès à la Plateforme sera suspendu et Votre Compte sera supprimé.</p>
          <h4>3.3.	SUPPRESSION DE VOTRE COMPTE </h4>
          <p>Vous pouvez à tout moment demander la suppression de votre Compte en adressant une demande à la Société, par courriel à l’adresse suivante : contact@askafox.com. Dès le traitement de votre demande par la Société, Votre Compte sera supprimé automatiquement par la Société. </p>
          <h3>ARTICLE 4.	DESCRIPTION DES FONCTIONNALITÉS COUVERTES PAR LA PLATEFORME UNE FOIS VOTRE COMPTE OUVERT</h3>
          <p>&nbsp;</p>
          <h4>4.1.	FONCTIONNALITÉS COUVERTES PAR LA PLATEFORME POUR UN ACHETEUR</h4>
          <p>Les fonctionnalités couvertes par la Plateforme pour un Acheteur sont notamment les suivantes :</p>
          <ul>
            <li>Accéder à son profil sur son Compte ;</li>
            <li>Modifier les informations du profil sur son Compte ;</li>
            <li>Rechercher des Experts en fonction de critères sélectionnés d’une case à cocher  (technologies, régions, industries, certifications, profil de la société etc.) ;</li>
            <li>Obtenir une liste de résultats de recherche d’Experts restreinte, en fonction des critères de recherche sélectionnés ;</li>
            <li>Accéder à une liste de résultats de recherche de données de fournisseurs étendue, en contrepartie du paiement d’un prix ;</li>
            <li>Accéder à une liste de résultats de recherche d’Experts étendue, gratuitement ;</li>
            <li>Déposer une demande de prestation d’audit sur la Plateforme aux fins de trouver un Expert puis remplir les informations de la mission (budget, monnaie, lieu d’exécution de la mission, calendrier, description, fourniture d’un cahier des charges avec possibilité de télécharger des documents) ;</li>
            <li>Acheter une prestation d’audit auprès d’un Expert.</li>
          </ul>
          <h4>4.2.	FONCTIONNALITÉS COUVERTES PAR LA PLATEFORME POUR UN EXPERT </h4>
          <p>Les fonctionnalités couvertes par la Plateforme pour un Expert sont notamment les suivantes : </p>
          <ul>
            <li>Accéder à son profil sur son Compte ;</li>
            <li>Modifier les informations du profil sur son Compte ;</li>
            <li>Se faire référencer sur la Plateforme en qualité d’Expert aux fins d’être mis en relation avec un Acheteur ;</li>
            <li>Chiffrer une demande de mission déposée par un Acheteur.</li>
          </ul>
          <h3>ARTICLE 5.	ÉVOLUTION DE LA PLATEFORME</h3>
          <p>L’ensemble des fonctionnalités de la Plateforme peuvent évoluer à tout instant. La Société pourra modifier à tout moment le contenu de la Plateforme, sa présentation et son fonctionnement sans que sa responsabilité ne puisse être engagée.</p>
          <h3>ARTICLE 6.	DISPONIBILITÉ DE LA PLATEFORME</h3>
          <p>La Plateforme est accessible 24 heures sur 24, 7 jours sur 7 sauf en cas de force majeure au sens de la législation française, et sous réserve des interventions éventuelles de maintenance qui sont nécessaires au bon fonctionnement de la Plateforme et des fonctionnalités qu’elle propose. </p>
          <h3>ARTICLE 7.	ASSISTANCE AUX UTILISATEURS </h3>
          <p>Pour toute question relative au fonctionnement de la Plateforme, Vous êtes invité à contacter la Société à l’adresse suivante : contact@askafox.com.</p>
          <h3>ARTICLE 8.	RESPONSABILITÉ </h3>
          <p>8.1.	La Société n’est tenue qu’à une obligation de moyens au titre de la fourniture et du bon fonctionnement de la Plateforme. </p>
          <p>La responsabilité de la Société ne saurait être engagée au titre des présentes, qu’en cas de faute ou négligence de la Société dûment démontrée par Vous : </p>
          <ul>
            <li>Si Vous ne respectez pas les conditions et modalités d’utilisation de la Plateforme ;</li>
            <li>Si Vous faites une utilisation anormale de la Plateforme ;</li>
            <li>En cas de survenance de tout dysfonctionnement et/ou dommage affectant la Plateforme, qui résulterait d’une faute ou d’une négligence d’un tiers.</li>
          </ul>
          <p>La Société ne peut pas être tenue pour responsable en cas de mauvaise connexion ou d’interruption des réseaux de télécommunication ayant un impact quant à l’accès à la Plateforme et ou indisponibilité totale ou partielle de la Plateforme.</p>
          <p>Vous déclarez avoir conscience que le fonctionnement de la Plateforme, repose en partie sur des informations que Vous fournissez à la Société, notamment en ce qui concerne les Données renseignées dans le formulaire d’inscription ou sur votre profil accessible sur Votre Compte via la Plateforme. En conséquence, la responsabilité de la Société ne saurait en aucun être engagée dans l’hypothèse ou les informations que Vous fournissez à la Société seraient incomplètes, périmées ou bien erronées.</p>
          <p>La Société ne sera en aucun cas tenue de réparer d’éventuels dommages indirects que Vous subissez à l’occasion de l’utilisation de la Plateforme, et plus largement, tous dommages qui ne résulteraient pas exclusivement et directement d’une faute ou d’une négligence de la Société.</p>
          <p>8.2.	En tant qu’Utilisateur, Vous déclarez connaître parfaitement les caractéristiques d’Internet et les contraintes liées à son utilisation, en particulier l’impossibilité de garantir l’intégrité et la confidentialité de toutes données y transitant. </p>
          <p>Dans le cadre de l’utilisation de la Plateforme, Vous Vous engagez à ne pas divulguer ou diffuser de contenus susceptibles de porter préjudice à l’image de la Société et/ou de tout autre Utilisateur et / ou de tout tiers et non conformes aux dispositions légales et règlementaires en vigueur, ou utiliser des programmes informatiques de nature à perturber, interrompre, détruire en tout ou en partie l’ensemble des services assurés par la Plateforme.</p>
          <p>Dans l’hypothèse où l’usage de la Plateforme permettrait d’éditer et / ou publier du contenu, Vous Vous engagez, dans le cadre de l’utilisation de la Plateforme à modérer votre langage et à ne pas divulguer ou diffuser de propos :</p>
          <ul>
            <li>De nature publicitaire ou promotionnel pour des produits ou services réglementés ou illicites,</li>
            <li>Contraire à l’ordre public, et / ou non conformes aux dispositions légales et règlementaires en vigueur,</li>
            <li>A caractère diffamatoire, injurieux, outrancier, mensonger, discriminatoire, ou calomnieux à l’égard d’un Utilisateur, de la Société ou d’un tiers, </li>
            <li>A caractère raciste, xénophobe, négationniste, incitant à la discrimination, à la haine ou à la violence à l'égard d'une personne ou d'un groupe de personnes à raison de leur origine, de leur sexe, de leur situation de famille, de leur apparence physique, de leur patronyme, de leur état de santé, de leur handicap, de leurs caractéristiques génétiques, de leurs mœurs, de leur orientation sexuelle vraie ou supposée, de leur âge, de leurs opinions politiques, de leurs activités syndicales, de leur appartenance vraie ou supposée à une ethnie, une nation, une race, ou une religion déterminée,</li>
            <li>Contenant des virus, ou tout programme informatique de nature à perturber, interrompre, détruire en tout ou en partie la Plateforme.</li>
          </ul>
          <p>De même, Vous Vous engagez à ne pas porter atteinte aux droits de propriété intellectuelle de la Société, d’un Utilisateur ou d’un tiers, ni diffuser aucune information de nature confidentielle ou dont la diffusion constituerait la violation d’un engagement contractuel vis-à-vis de la Société, d’un Utilisateur ou d’un tiers. </p>
          <p>Vous reconnaissez utiliser les données accessibles via  la Plateforme sous votre entière responsabilité. Vous renoncez à exercer tous recours contre la Société dans le cadre de poursuites diligentées par un tiers, ou un autre Utilisateur, du fait de la publication de tout propos ou avis, ou du fait de tout manquement aux obligations découlant des présentes. </p>
          <p>Vous reconnaissez qu’il est interdit de réaliser toute action de nature à interrompre la Plateforme, à restreindre sa disponibilité ou à empêcher sa continuité. Toute intrusion, ou tentative d’intrusion, au sein de la Plateforme, détournement de données, atteinte aux mesures de sécurité et d’authentification de la Plateforme ouvrent droit à des poursuites judiciaires.</p>
          <p>Tout manquement grave d'un Utilisateur à l'un quelconque de ses engagements au titre des présentes Conditions Générales d’Utilisation, non remédié dans un délai de quinze (15) jours à compter de la réception d'une lettre recommandée avec avis de réception ou d’un courriel avec accusé de réception de la Société, ouvre droit pour cette dernière à la suspension ou à la révocation de l’accès de l’Utilisateur concerné, selon la gravité des faits, ou à l’interdiction d’utilisation de la Plateforme le cas échéant. Le courrier ou courriel précisera les faits reprochés. En outre, tout accès de l’Utilisateur à son Compte et / ou aux fonctionnalités concernées de la Plateforme est impossible, sans préjudice de tout autre dommage et intérêt auxquels la Société pourrait prétendre. En cas de trouble ou atteinte manifestement illicite, le délai pour remédier au manquement pourra être plus court.</p>
          <h3>ARTICLE 9.	PROPRIÉTÉ INTELLECTUELLE </h3>
          <p>La Plateforme, les fonctionnalités qui y sont proposées ainsi que tous les éléments les constituant sont la propriété pleine et entière de la Société, en ce compris les droits de propriété intellectuelle y afférents, et toutes marques et logos utilisés par la Société.</p>
          <p>La Société Vous concède indirectement à titre gratuit une licence limitée, personnelle, non transmissible et non exclusive, aux fins d’utiliser la Plateforme dans les conditions prévues aux présentes. Tout autre usage est expressément exclu.</p>
          <p>Les contenus mis à votre disposition dans le cadre de la Plateforme sont utilisables uniquement dans ce cadre, sans droit d’utilisation, reproduction et / ou diffusion à titre commercial, et sous Votre seule responsabilité. </p>
          <p>Vous ne pouvez en aucun cas, que ce soit directement ou avec l’assistance d’un tiers ou d’un autre Utilisateur, sans l’accord préalable et écrit de la Société, sauf dans les conditions strictement prévues par la loi :</p>
          <ul>
            <li>Modifier la Plateforme et ses fonctionnalités,</li>
            <li>Reproduire la Plateforme et ses fonctionnalités,</li>
            <li>Diffuser la Plateforme à titre commercial,</li>
            <li>Arranger, adapter, traduire la Plateforme et ses fonctionnalités,</li>
            <li>Corriger la Plateforme et ses fonctionnalités,</li>
            <li>Extraire tout ou partie de la Plateforme et des données y figurant,</li>
            <li>Décompiler la Plateforme.</li>
          </ul>
          <h3>ARTICLE 10.	DONNÉES A CARACTÈRE PERSONNEL</h3>
          <p>La collecte de Vos Données à Caractère Personnel et leur traitement, ainsi que l’ensemble des modalités afférentes, sont décrits au sein de la Politique de Confidentialité de la Société conformément au Règlement  : <a routerLink="/information/privacy-terms">Privacy policy</a></p>
          <p>Pour rappel, Vos droits en tant qu’Utilisateur sont les suivants :</p>
          <ul>
            <li>Droit à retirer Votre consentement ;</li>
            <li>Droit d’accès à Vos Données à Caractère Personnel ;</li>
            <li>Droit de rectifier ou supprimer Vos Données à Caractère Personnel ;</li>
            <li>Droit à la portabilité de Vos Données à Caractère Personnel.</li>
          </ul>
          <p>Ils peuvent être exercés auprès de la Société à l’adresse de courriel suivante : <a href="mailto:contact@askafox.com">contact@askafox.com</a></p>
          <p>et / ou qui serait susceptible de porter atteinte aux droits des tiers ou d’un autre Utilisateur. </p>
          <h3>10.1.	 DROIT APPLICABLE - RÉGLEMENT AMIABLE DES LITIGES - TRIBUNAL COMPÉTENT</h3>
          <p>Les présentes Conditions Générales d’Utilisation sont soumises au droit français. Tout Utilisateur et la Société feront leur possible pour résoudre à l’amiable tout litige pouvant découler de l’interprétation, l’application et/ou l’exécution des présentes Conditions Générales d’Utilisation. Si une telle solution ne peut aboutir, l’Utilisation et la Société conviennent dès à présent que le litige sera soumis à la compétence des tribunaux de Lyon.</p>
        </div>
      </div>
    </div>
  </section>
</main>