import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ExpertSearchService } from '../../_services/expert-search.service'
import { Expert } from '../../_models/expert';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';

@Component({
  selector: 'app-expert-detail',
  templateUrl: './expert-detail.component.html',
  styleUrls: ['./expert-detail.component.css']
})
export class ExpertDetailComponent implements OnInit {

  expert: Expert;
  expertMatchId : number;
  
  constructor(
    private route: ActivatedRoute,
    private expertSearchService: ExpertSearchService,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getExpert();
  }

  getExpert(): void {
    this.expertMatchId = +this.route.snapshot.paramMap.get('id');
    this.expert = this.expertSearchService.getExpertMatchById(this.expertMatchId).expert;
  }

  goBack(): void {
    this.location.back();
  }
}
