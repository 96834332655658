<main class="main">
  <!-- section start-->
  <section class="hero-block buyer-block ">
    <!--picture>
      <source srcset="assets/promo_6.jpg" media="(min-width: 992px)" /><img class="img--bg" src="assets/promo_6.jpg"
        alt="img" />
    </picture-->
    <div class="buyer-block__layout"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="align-container">
            <div class="align-container__item "><span class="hero-block__overlay">Aska</span>
              <h1 class="hero-block__title top-140" i18n>Expert Profile</h1>
              <H4>{{expert.lastName}} <strong>{{expert.firstName}}</strong> </H4>
              <div class="row">
                <div class="col-xl-6 top-20 ">
                  <div type="submit" class="button button--promo2" routerLink="/user/login" (click)="logout()"><span i18n>Log out</span>
                    <svg class="icon">
                      <use xlink:href="#arrow"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="bottom-30">
    <div class="container">
      <div class="container">
        <div class="row bottom-30">
          <div class="col-12">
            <h5 class="top-40 bottom-20" i18n> Status of ongoing/archived missions </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <table mat-table [dataSource]="expertMissions" class="mat-elevation-z8">

              <!-- Mission title -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef i18n> Mission title </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
              </ng-container>

              <!-- Proposal sent date -->
              <ng-container matColumnDef="sent_date">
                <th mat-header-cell *matHeaderCellDef i18n> Proposal sent date </th>
                <td mat-cell *matCellDef="let element"> {{element.proposalSentDateFormat | date:'longDate'}} </td>
              </ng-container>

              <!-- Mission Type -->
              <ng-container matColumnDef="missoinType">
                <th mat-header-cell *matHeaderCellDef i18n> Type of mission </th>
                <td mat-cell *matCellDef="let element"> {{element.missionType.value}} </td>
              </ng-container>

              <!-- Budget -->
              <ng-container matColumnDef="budget">
                <th mat-header-cell *matHeaderCellDef i18n> Budget </th>
                <td mat-cell *matCellDef="let element"> {{element.budget}} {{element.currency}}</td>
              </ng-container>
              <!-- Deadline -->
              <ng-container matColumnDef="deadline">
                <th mat-header-cell *matHeaderCellDef i18n> Mission execution deadline </th>
                <td mat-cell *matCellDef="let element"> {{element.deadlineFormat | date:'longDate'}} </td>
              </ng-container>

              <!-- Description -->
              <ng-container matColumnDef="description" i18n>
                <th mat-header-cell *matHeaderCellDef> Mission description & deliverable expected or means to be
                  provided </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
              </ng-container>

              <!-- Status -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef i18n> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.missionStatus.value}} </td>
              </ng-container>

              <!-- Information -->
              <ng-container matColumnDef="information">
                <th mat-header-cell *matHeaderCellDef i18n> Information </th>
                <td mat-cell *matCellDef="let element" [ngSwitch]="element.missionStatus.value">
                  <mat-label *ngSwitchCase="enumMissionStatus.MISSION_SENT" i18n>Do you accept the offer?</mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.EXPERT_REJECTED" i18n>Reason for rejection:
                    {{element.rejectionReason}}</mat-label>
                  <mat-label
                    *ngSwitchCase="[enumMissionStatus.EXPERT_ACCEPTED,
                                            enumMissionStatus.BUYER_ACCEPTED_COUNTEROFFER].includes(element.missionStatus.value) ? element.missionStatus.value : !element.missionStatus.value" i18n>
                    Waiting for quiz question from customer
                  </mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.COUNTEROFFER" i18n>Waiting for counteroffer validation
                  </mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.BUYER_REJECTED_COUNTEROFFER" i18n>Thanks for your time. We
                    encourage you to try other opportunities</mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.MISSION_ACCEPTED_AND_QUIZ_ANSWERED" i18n>Anwser sent to
                    customer</mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.MISSION_ACCEPTED_AND_QUIZ_SENT" i18n>Quiz:
                    {{element.quiz}}</mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.MISSION_ACCEPTED_AND_ANSWER_VALIDATED" i18n>You’ve been vetted!
                    We’re waiting for payment from customer.</mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.MISSION_ACCEPTED_BUT_ANSWER_NOT_VALIDATED" i18n>Sorry the quiz
                    didn’t pass. Try your luck next time!</mat-label>
                  <mat-label *ngSwitchCase="enumMissionStatus.MISSION_ACCEPTED_AND_PAID_FOR" i18n>Congrats! You have been
                    chosen. Wait for your mission summary by email please.</mat-label>
                  <mat-label *ngSwitchDefault></mat-label>
                </td>
              </ng-container>

              <!-- Action -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef i18n> Action </th>
                <td mat-cell *matCellDef="let element" [ngSwitch]="element.missionStatus.value">
                  <ng-template [ngSwitchCase]="enumMissionStatus.MISSION_SENT">
                    <span>
                      <div type="submit" (click)="accept(element.missionId)"
                        class="button button--filled top-10 bottom-10" i18n>Accept</div>
                    </span>
                    <span>
                      <div type="submit" (click)="decline(element.missionId)" class="button button--out bottom-10">
                        Decline</div>
                    </span>
                    <span>
                      <div type="submit" (click)="counteroffer(element.missionId)"
                        class="button button--outline bottom-10">Counteroffer</div>
                    </span>
                  </ng-template>
                  <ng-template
                    [ngSwitchCase]="[enumMissionStatus.EXPERT_REJECTED,
                                                enumMissionStatus.EXPERT_ACCEPTED,
                                                enumMissionStatus.COUNTEROFFER,
                                                enumMissionStatus.BUYER_ACCEPTED_COUNTEROFFER,
                                                enumMissionStatus.BUYER_REJECTED_COUNTEROFFER,
                                                enumMissionStatus.MISSION_ACCEPTED_AND_QUIZ_ANSWERED,
                                                enumMissionStatus.MISSION_ACCEPTED_AND_ANSWER_VALIDATED,
                                                enumMissionStatus.MISSION_ACCEPTED_BUT_ANSWER_NOT_VALIDATED,
                                                enumMissionStatus.MISSION_ACCEPTED_AND_PAID_FOR].includes(element.missionStatus.value) ? element.missionStatus.value : !element.missionStatus.value">
                  </ng-template>
                  <ng-template [ngSwitchCase]="enumMissionStatus.MISSION_ACCEPTED_AND_QUIZ_SENT">
                    <div type="submit" (click)="answerQuiz(element.missionId)"
                      class="button button--filled top-10 bottom-10" i18n>Answer quiz</div>
                  </ng-template>
                  <ng-template ngSwitchDefault></ng-template>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- map end-->
</main>