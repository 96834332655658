<div *ngIf="buyer; then buyerBlock else loginBlock"></div>
<ng-template #buyerBlock>
  <div *ngIf="resultFound; then resultBlock else emailBlock"></div>
  <ng-template #resultBlock>
    <div class="indication" style="padding-top: 140px;">Congratulations! We have found {{dataNumber}} resultats matching your criterion.</div>
    <div class="container top-30 bottom-30">
      <div class="row">
        <div class="col-xl-12" class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" >
            <!-- No. Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Company Name Column -->
            <ng-container matColumnDef="regions">
              <th mat-header-cell *matHeaderCellDef> Region </th>
              <td mat-cell *matCellDef="let element"> {{element.expert.expertRegions[0].country}}</td>
            </ng-container>

            <!-- Company Name Column -->
            <ng-container matColumnDef="industries">
              <th mat-header-cell *matHeaderCellDef> Industry </th>
              <td mat-cell *matCellDef="let element"> {{element.expert.expertIndustries[0].value}}</td>
            </ng-container>

            <!-- Company Name Column -->
            <ng-container matColumnDef="coreCompetency">
              <th mat-header-cell *matHeaderCellDef> Core Competency </th>
              <td mat-cell *matCellDef="let element"> {{element.expert.expertCoreCompetencies[0].value}}</td>
            </ng-container>

            <!-- Company Name Column -->
            <ng-container matColumnDef="matchingRate">
              <th mat-header-cell *matHeaderCellDef> Matching rate </th>
              <td mat-cell *matCellDef="let element"> {{element.matchingRate}}</td>
            </ng-container>

            <!-- Supplier Détail button -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <div type="submit" class=" button button--green" [routerLink]="['/experts-web/expert-detail', element.id]">
                  <span>More</span>                
                  <svg class="icon">
                    <use xlink:href="#arrow"></use>
                    </svg>
                  </div>
              </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons 
            aria-label="Select page of periodic elements">
          </mat-paginator>
        
        </div>
      </div>
    </div>
    <section class="locations-section">
      <p><iframe src="//fr.batchgeo.com/map/267780010f249d1498cddc6d3ee06150" frameborder="0" width="100%" height="550" sandbox="allow-top-navigation allow-scripts allow-popups allow-same-origin allow-modals allow-forms" style="border:1px solid #aaa;"></iframe></p>
    </section>
  </ng-template>
  <ng-template #emailBlock>
    <!-- Request start-->
    <div class="front-promo ">
      <div class="front-promo__layout"></div>
      <div class="align-container">
        <div class="align-container__item">
            <div class="container">
            <div class="row">
              <div class="col-lg-12 col-xl-12 offset-xl-1">
                <div class="heading top-40 "><span class="promo-slider__overlay"></span>
                  <h3 class="heading__title" i18n>Your request has been registered.</h3>
                </div>
                <p i18n>We are currently processing it with latest market intelligence.
                </p>
                <p i18n>You’ll be notified by email as soon as a match is found.</p>
              </div>
            </div>
            </div>					
          </div>
        </div>
    </div>		
    <!-- Request end-->
  </ng-template>
</ng-template>
<ng-template #loginBlock>
  <div class="front-promo ">
    <div class="front-promo__layout"></div>
    <div class="align-container">
      <div class="align-container__item">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-xl-12 offset-xl-1">
              <div class="heading top-40 "><span class="promo-slider__overlay"></span>
                <h3 class="heading__title" i18n>Please login in as a buyer to show the results.</h3>
                <a class=" button button--white" routerLink="/user/login"><span i18n>Log in</span>
                  <svg class="icon">
                    <use xlink:href="#arrow"></use>
                  </svg></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>