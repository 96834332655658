import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';

import { BuyerService } from '../../_services/buyer.service';
import { Buyer } from '../../_models/buyer';
import { ExpertService } from '../../_services/expert.service';
import { Expert } from '../../_models/expert';

import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  buyer : Buyer = null;
  expert : Expert = null;
  selected;
  
  locales = [
    { code: 'en-US', name: 'English' },
    { code: 'fr', name: 'Français' }
  ];

  constructor(
    private buyerService : BuyerService, 
    private expertService : ExpertService,
    private googleAnalyticsEventsService : GoogleAnalyticsEventsService,
    @Inject(LOCALE_ID) public activeLocale : string) { }

  ngOnInit(): void {
    this.buyerService.buyer$.subscribe(data => this.buyer = data);
    this.expertService.expert$.subscribe(data => this.expert = data);
    this.selected = this.activeLocale;
  }

  logout() {

    if (this.buyer) {
      this.buyerService.logout();
    }
    
    if (this.expert) {
      this.expertService.logout();     
    }

  }

  onChange(event) {
    console.info("onchange", event.value, this.activeLocale);
    this.activeLocale = event.value;
    window.location.href = `/${this.activeLocale}`;
    this.googleAnalyticsEventsService.emitEvent("header", "locale_click", this.activeLocale, 0);
  }

  login() {
    this.googleAnalyticsEventsService.emitEvent("header", "login", "Login in", 0);
  }

}
