<main class="main mobile-top">
  <section class="section blog">
    <div class="container">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <h1>Mentions légales</h1>
          <h3>1. IDENTIFICATION</h3>
          <p>Éditeur : La plateforme ASKAFOX accessible en mode Saas (ci-après la « Plateforme ») est éditée par la société ASKAFOX, société par actions simplifiée, au capital de 30 000,00 € dont le siège social est situé 43 rue Waldeck Rousseau, 69006 LYON, immatriculée au registre du commerce et des sociétés de Lyon sous le numéro 902201300, numéro de TVA intracommunautaire : FR 11902201300 (ci-après la « Société »)</p>
          <p></p>
          <p>Numéro de téléphone : +33 6 78 15 87 18</p>
          <p></p>
          <p>Adresse de courriel : contact@askafox.com</p>
          <p>Directeur de la publication de la Plateforme : Monsieur Hanyin CHEN</p>
          <p>Hébergeur de la Plateforme : OVH, Société par actions simplifiée au capital de 10 069 020,00 € dont le siège social est situé 2 rue Kellermann, 59100 Roubaix, immatriculée au registre du commerce et des sociétés de LILLE sous le numéro unique 424 761 419, numéro de TVA intracommunautaire : FR 22 424 761 419</p>
          <h3>2. COOKIES</h3>
          <p>La navigation sur la Plateforme est susceptible de provoquer l’installation de cookies sur l’ordinateur d’un utilisateur. </p>
          <p>Pour plus d’informations, tout utilisateur de la Plateforme doit consulter la politique de confidentialité et de gestion des cookies en cliquant sur le lien suivant : <a routerLink="/information/privacy-terms">Privacy policy</a></p>
          <h3>3. LIENS HYPERTEXTES ET SITES INTERNETS TIERS</h3>
          <p>La mise en place par l’utilisateur de tous liens hypertextes vers tout ou partie de la Plateforme est strictement interdite, sauf autorisation préalable et écrite de la Société, sollicitée par courriel à l’adresse suivante : <a href="mailto:contact@askafox.com">contact@askafox.com</a>.</p>
          <p>La Société est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa décision. Dans le cas ou la Société accorderait son autorisation, celle-ci n’est dans tous les cas que temporaire et pourra être retirée à tout moment, sans obligation de justification de la Société.</p>
          <p>La Société peut insérer sur la Plateforme des liens hypertextes vers des sites internet tiers. La Société ne pourra être tenue responsable du fonctionnement et du contenu de ces sites, et des dommages pouvant être subis par tout utilisateur lors d’une visite de ces sites. </p>
          <p>Des sites tiers peuvent éventuellement contenir des liens hypertextes vers la Plateforme.</p>
          <h3>4. PROPRIÉTÉ INTELLECTUELLE</h3>
          <p>La Plateforme et l’ensemble des éléments la constituant, et notamment mais non exclusivement les textes, graphismes, vidéos, animations, marques et logos (ci-après les « Éléments ») sont protégés au titre de la propriété intellectuelle.</p>
          <p>Ces Éléments sont la propriété exclusive la Société.</p>
          <p>En conséquence, toute utilisation des Éléments par qui que ce soit en dehors de l’utilisation de la Plateforme telle que définie aux présentes, est strictement interdite, sauf accord préalable de la Société.</p>
          <p>Pour plus d’informations, tout utilisateur de la Plateforme doit consulter les Conditions Générales d’Utilisation de la Plateforme en cliquant sur le lien suivant : <a routerLink="/information/general-terms">Terms and conditions</a>. </p>
          <p>La Société se réserve la faculté d’engager la responsabilité de quiconque qui agirait en violation de ses droits de propriété intellectuelle. </p>
          <h3>5. UTILISATION DE LA PLATEFORME</h3>
          <p>La Plateforme est accessible à tout utilisateur disposant d’un accès à internet et ayant accès aux fonctionnalités et services proposés par ladite Plateforme. Tous les coûts relatifs à l’accès à la Plateforme restent à la charge de l’utilisateur.</p>
        </div>
      </div>
    </div>
  </section>
</main>