<h5 mat-dialog-title>Send a quiz</h5>

<mat-dialog-content [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill" style="width: 600px;">
    <mat-label for="quiz">Test your expert</mat-label>
    <textarea formControlName="quiz" matInput rows="5"></textarea>
  </mat-form-field>
  <br/>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="row">
    <div class="col-6">
      <div class="button button--filled right-10" (click)="onSubmit()" type="submit">Submit</div>
    </div>
    <div class="col-6">
      <div class="button button--out" (click)="cancel()" type="submit">Cancel</div>
    </div>
  </div>
</mat-dialog-actions>
