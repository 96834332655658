import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-answer-quiz',
  templateUrl: './dialog-answer-quiz.component.html',
  styleUrls: ['./dialog-answer-quiz.component.css']
})
export class DialogAnswerQuizComponent implements OnInit {

  form : FormGroup;
  submitted = false;

  constructor(
    private builder: FormBuilder,
    private dialogRef: MatDialogRef<DialogAnswerQuizComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.builder.group({
      quiz_answer: ['', Validators.required]
    }); 
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
  
  cancel() : void {
    // Close the dialog without sending data
    this.dialogRef.close();
  }


}
