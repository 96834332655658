export enum JobFunction {
  GENERAL_MANAGEMENT = "General management",
  SUPPLY_CHAIN = "Supply chain",
  SALES = "Sales",
  PRODUCT_MANAGEMENT = "Product management",
  CUSTOMER_SERVICE_SUPPORT = "Customer service & support",
  FINANCE = "Finance",
  INNOVATION_STRATEGY = "Innovation & strategy",
  QUALITY_AUDIT = "Quality & Audit",
  IT = "IT"
}

export enum JobLevel {
  OWNER = "Owner",
  CXO_HEAD_OF_FUNCTION = "CXO/Head of function",
  SALES = "Sales",
  VP_DIRECTOR = "VP/Director",
  MANAGER = "Manager",
  INDIVIDUAL_CONTRIBUTOR = "Individual contributor",
  CONSULTANT = "Consultant",
}

export enum Industry {
  AEROSPACE_AND_AVIATION = "Aerospace and aviation",
  AGRICULTURAL_AND_FORESTRY_MACHINERY = "Agricultural and forestry machinery",
  AUTOMATION_AND_CONTROL = "Automation and control",
  AUTOMOTIVE_AND_VEHICLE = "Automotive and vehicle",
  BOILER_CONTAINER_AND_TANK = "Boiler, container, and tank",
  CLEAN_ROOM_TECHNOLOGY="Clean room technology",
  CONSTRUCTION_AND_ARCHITECTURAL_SUPPLIES="Construction and architectural supplies",
  CONSUMER_GOODS = "Consumer goods",
  CHEMICAL_INDUSTRY = "Chemical industry",
  DEFENSE="Defense",
  DISTRIBUTION="Distribution ",
  ENVIRONMENT="Environment",
  ELECTRICAL_INDUSTRY="Electrical industry",
  FASTENERS="Fasteners",
  FOOD="Food",
  FURNITURE="Furniture",
  HANDLING_LOGISTICS_STORAGE="Handling/logistics/storage",
  HEATING_VENTILATION_AND_AIR_CONDITIONING="Heating, ventilation, and air conditioning",
  HOUSEHOLD_APPLIANCE_INDUSTRY="Household appliance industry",
  HYDRAULIC_AND_PNEUMATIC_INDUSTRY="Hydraulic and pneumatic industry",
  ICT_INFORMATION_AND_COMMUNICATION_TECHNOLOGY="ICT (Information and communication technology)",
  INFRASTRUCTURE_BRIDGES_HIGHWAY_TUNNEL="Infrastructure (bridges, highway, tunnel)",
  LIGHTING_INDUSTRY="Lighting industry",
  MACHINE_TOOL_MANUFACTURING="Machine tool manufacturing",
  MAINTENANCE_REPAIR_AND_OVERHAUL="Maintenance Repair & Overhaul",
  MECHANICAL_ENGINEERING="Mechanical engineering",
  MEDICAL_AND_PHARMACEUTICAL_TECHNOLOGY="Medical & pharmaceutical technology",
  MEASUREMENT_AND_CONTROL_="Measurement and control ",
  MINING="Mining",
  MOTORS_AND_TRANSMISSION="Motors and Transmission",
  NAVAL_AND_SHIPBUILDING="Naval & shipbuilding ",
  NON_FOSSIL_ENERGY="Non-fossil energy",
  OFFICE_SUPPLY="Office supply",
  OIL_AND_GAS="Oil & Gas",
  PACKAGING_INDUSTRY="Packaging industry",
  PAPER_AND_PRINTING="Paper and printing",
  PLANT_AND_PROCESS_CONSTRUCTION="Plant & process construction",
  PRECISION_MECHATRONICS_AND_OPTICS="Precision, mechatronics, and optics",
  RAILWAY_AND_ROLLING_STOCK="Railway & rolling stock",
  SPECIAL_PURPOSE_MACHINERY="Special purpose machinery",
  OTHERS="Others",
}


export enum Region {
  ALBANIA="Albania",
  ALGERIA="Algeria",
  ARGENTINA="Argentina",
  AUSTRALIA="Australia",
  AUSTRIA="Austria",
  BANGLASH="Bangladesh",
  BELGIUM="Belgium",
  BOSNIA="Bosnia & Herzegovina",
  BRAZIL="Brazil",
  BULGARIA="Bulgaria",
  CAMBOGIA="Cambodia",
  CANADA="Canada",
  CHILE="Chile",
  CHINA="China (PRC)",
  CROATIA="Croatia",
  CZECH_REPUBLIC="Czech Republic",
  DENMARK="Denmark",
  EGYPT="Egypt",
  FINLAND="Finland",
  FRANCE="France",
  GERMANY="Germany",
  GREECE="Greece",
  HONGKONG='Hong Kong S.A.R of China',
  HUNGARY="Hungary",
  INDIA="India",
  INDONESIA="Indonesia",
  IRELAND="Ireland",
  ISRAEL="Israel",
  ITALY="Italy",
  JAPAN="Japan",
  LATVIA="Latvia",
  LITHUANIA="Lithuania",
  MALAYSIA="Malaysia",
  MEXICO="Mexico",
  MOROCCO="Morocco",
  NETHERLANDS="Netherlands",
  NEW_ZEALAND="New Zealand",
  NORWAY="Norway",
  PAKISTAN="Pakistan",
  PHILIPPINES="Philippines",
  POLAND="Poland",
  PORTUGAL="Portugal",
  ROMANIA="Romania",
  RUSSIA="Russia",
  SERBIA="Serbia",
  SINGAPORE="Singapore",
  SLOVAKIA="Slovak Republic",
  SLOVENIA="Slovenia",
  SOUTH_AFRICA="South Africa",
  SOUTH_KOREA="South Korea",
  SPAIN="Spain",
  SRI_LANKA="Sri Lanka",
  SWEDEN="Sweden",
  SWITZERLAND="Switzerland",
  TAIWAN="Taiwan",
  THAILAND="Thailand",
  TUNISIA="Tunisia",
  TURKEY="Turkey",
  UK="UK",
  UKRAINE="Ukraine",
  USA="USA",
  VIETNAM="Vietnam"
}

export enum Technology {
  ENGINEERING="Engineering",
  SHEETMETAL_WORKING_CUTTING="Sheetmetal working, cutting",
  BOILER_MAKING_FORGING="Boilermaking, forging",
  WELDING="Welding",
  MACHINING="Machining",
  CASTING="Casting",
  SURFACE_TREATMENT_FINISHING="Surface treatment finishing",
  GEARS_WIRES_SPRINGS="Gears wires, springs",
  TUBE_AND_PIPES="Tube & pipes",
  FASTENERS="Fasteners",
  FITTINGS="Fittings",
  HYDRAULICS_AND_PNEUMATICS="Hydraulics & pneumatics",
  ROBOTICS_AND_AUTOMATISM="Robotics & automatism",
  PRINTING_3D="3d printing",
  POLYMER="Polymer",
  ELECTRONIC="Electronic ",
  ELECTRONIC_PRINTED_CIRCUIT_BOARDS="Electronic: printed circuit boards",
  ELECTRONIC_OTHERS="Electronic: others",
  WOOD_TEXTILE_AND_CERAMIC="Wood, textile & ceramic",
  ASSEMBLY="Assembly",
  WIRE_HARNESS="Wire harness",
  CONTROLLING_AND_MEASUREMENT="Controlling",
  TOOLING="Tooling",
  PACKAGING="Packaging",
  ON_SITE_SERVICING="On -site servicing",
  SPECIAL_PURPOSE_MACHINE="Special purpose machine",
  OTHERS="Others",
}

export enum Certification {
  DIN_18800_7_2008_Class_A_C_minor_welding_cert="DIN 18800-7:2008 Class A-C (minor welding cert.)",
  DIN_18800_7_2008_Class_D_E_major_welding_cert="DIN 18800-7:2008 Class D_E (major welding cert.)",
  DIN_4113="DIN 4113",
  DIN_EN_1090="DIN EN 1090",
  DIN_EN_ISO_3834="DIN EN ISO 3834",
  DIN_6701_2="DIN 6701-2",
  EN_15085="EN 15085",
  EN_9100="EN 9100",
  EN_ISO_13485="EN ISO 13485",
  IATF_16949__2016="IATF 16949 :2016",
  ISO_14001="ISO 14001",
  ISO_9001_2008="ISO 9001:2008",
  ISO_9001_2015="ISO 9001:2015",
  ISO_TS_16949_2002="ISO-TS 16949:2002",
  ISO_TS_16949_2009="ISO-TS 16949:2009",
  IRIS="IRIS",
  CEFRI="CEFRI",
  JAR_145="JAR 145",
  OHSAS_18000="OHSAS 18000",
  OHSAS_18001="OHSAS 18001",
  PART_145="PART 145",
  QSF_A="QSF-A",
  QSF_B="QSF-B",
  SA_8000="SA 8000",
  VDA_6_1="VDA 6.1",
  VDA_6_2="VDA 6.2",
  VDA_6_4="VDA 6.4",
}

export enum Size {
  SMALL_AND_MEDIUM_MINUS_250P="Small & medium (<250p)",
  NOT_SO_SMALL_ENTRE_250_ET_1000P="Not so small (250-1000p)",
  SIZABLE_ENTRE_1000P_ET_5000P="Sizable (1000p-5000p)",
  BIG_GUY_PLUS_5000P="Big guy (>5000p)",
}

export enum Businessmodel {
  TRADER="Trader",
  MANUFACTURER="Manufacturer",
  INTEGRATOR="Integrator"
}

export enum Position {
  TIER_1_SUPPLIER="Tier 1 Supplier",
  TIER_2_SUPPLIER="Tier 2 Supplier",
  TIER_3_SUPPLIER="Tier 3 Supplier"
}

export enum CompetitiveForce {
  PRICE ="Price",
  LEADTIME ="Leadtime",
  TECHNICAL_CAPABILITIES ="Technical capabilities",
  PRODUCTION_CAPACITY ="Production capacity",
  PRODUCTION_FLEXIBILITY="Production flexibility",
  CUSTOMER_RESPONSIVENESS ="Customer responsiveness",
  OPERATION_INTEGRITY ="Operation integrity"
 }

export enum CoreCompetency {
  QUALITY_SYSTEM="Quality System",
  MARKET_INSIGHT="Market Insight",
  TECHNOLOGY_KNOWHOW="Technology Knowhow"
}

export enum MissionType {
  SUPPLY_MARKET_INSIGHT = "Supply market insight: 1-hour phone call",
  TECHNOLOGY_CONSULTING = "Technology consulting: 1-hour phone call",
  ON_SITE_AUDIT_HALF_DAY = "On site Audit: half day",
  ON_SITE_AUDIT_WHOLE_DAY = "On site Audit: 1 day"
}

export enum Material {
  PARTICULE = "Particle reinforced composite materials"
}

export interface JobFunctionItem {
  jobFunctionId : number;
  value : JobFunction;
}

export interface JobLevelItem {
  jobLevelId : number;
  value : JobLevel; 
}

export interface IndustryItem {
  industryId : number;
  value : Industry;
}

export interface RegionItem {
  regionId : number;
  region : String;
  category : String;
  country : Region;
}

export interface SizeItem {
  sizeId : number;
  value : Size; 
}

export interface PositionItem {
  positionId : number;
  value : Position; 
}

export interface TechnologyItem {
  technologyId : number;
  value : Technology;
}

export interface CoreCompetencyItem {
  coreCompetencyId : number;
  value : CoreCompetency;
}

export interface CertificationItem {
  certificationId : number;
  value : Certification;
}

export interface BusinessmodelItem {
  businessmodelId : number;
  value : Businessmodel;
}

export interface competitiveForceItem {
  competitiveForceId : number;
  value : CompetitiveForce;
}

export interface materialItem {
  materialId : number;
  value : Material;
} 