import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { NewsletterService } from '../../_services/newsletter.service';
import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('testimonialModal') testimonialModal: SlickCarouselComponent;
  form: FormGroup;
  loading = false;

  mainslides = [
    {
      img: "assets/home_buyer.jfif",
      title : "Become our buyer",
      description : "Find a supplier and book an audit",
      link : "/information/buyer-presentation"
    },
    {
      img: "assets/home_expert.jfif",
      title : "Become our expert",
      description : "Accomplish a mission and get paid",
      link : "/information/expert-presentation"
    }
  ];

  mainsliderConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "fade" : true,
    "adaptiveHeight": true,
    "infinite" : true,
    "speed": 500
  };

  subslides = [
    {
      img: "assets/ASKAFOX_PICTO1.png",
      title : "I am a buyer",
      index : 0
    },
    {
      img: "assets/ASKAFOX_PICTO3.png",
      title : "I am an expert",
      index : 1
    }
  ];

  subslidessStatus = [
    true,
    false,
    false
  ]

  testimonials = [
    {
      text : "In god we trust , all others we audit",
      author : "- A SBF 120 Chief procurement officer"
    },
    {
      text : "We need to break the century-old myth in modern industry: Single greatest source of suppliers was \"Mouth To Ear\"",
      author : "- A smart guy I know"
    },
    {
      text : "Supply knowledge don't age like wine.That's why we need it from the expert who knows at the moment he knows",
      author : "- A telecom SMB owner in Lyon"
    }
  ];

  testimonialConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "fade" : true,
    "adaptiveHeight": true,
    "infinite" : true,
    "speed": 500,
    "autoplay" : true
  };

  constructor(private builder: FormBuilder,
    private newsletterService : NewsletterService,
    private dialog: MatDialog,
    private googleAnalyticsEventsService : GoogleAnalyticsEventsService
  ) {}

  ngOnInit(): void {
    this.form = this.builder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  goToIndex(index : number, mainSlider) {
    // Only trace I'm expert click, not i'm buyer
    if (index == 1) {
      this.googleAnalyticsEventsService.emitEvent("home", "i_am_expert_click", "I'm expert click", 0);
    }
    //Indicate which subslide is active
    for (var i = 0; i < this.subslidessStatus.length; i++) {
      if (i==index) {
        this.subslidessStatus[i] = true;
      } else {
        this.subslidessStatus[i] = false;
      }
    }

    mainSlider.slickGoTo(index);
  }

  buttonMoreClick(link : string) {
    console.log(link);
    if (link == "/information/buyer-presentation") {
      this.googleAnalyticsEventsService.emitEvent("home", "i_am_buyer_more_click", "I'm buyer button More click", 0);
    } else if (link == "/information/expert-presentation") {
      this.googleAnalyticsEventsService.emitEvent("home", "i_am_expert_more_click", "I'm expert button More click", 0);
    }

  }

  testimonialModalPrevClick() {
    this.googleAnalyticsEventsService.emitEvent("home", "testimonial_previous_click", "Previous testimonial Click", 0);
    this.testimonialModal.slickPrev();
  }

  testimonialModalNextClick() {
    this.googleAnalyticsEventsService.emitEvent("home", "testimonial_next_click", "Next testimonial Click", 0);
    this.testimonialModal.slickNext();
  }

  // convenience getter for easy accesss to form fields
  get f() { return this.form.controls; }

  onSubmit() {

    this.googleAnalyticsEventsService.emitEvent("home", "newsletter_subscribe", "Newsletter subscribe before", 0);

    if (!this.loading && this.form.valid) {
      this.loading = true;
      this.newsletterService.addNewsletterEmail(this.form.value).subscribe(
        data => {
          console.log("email sent");
          if (data) {
            this.addNewsletterEmailOK();
          } else {
            this.emailAlreadyAdded();
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    }
  }

  addNewsletterEmailOK(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Registration successful',
      message: 'Thanks. We\'ll come back to you with more exciting news!'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }

  emailAlreadyAdded(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Already registered',
      message: 'Hey we know you! You\'re already registered :-)'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }

}
