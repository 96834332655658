<main class="main">
  <!-- section start-->
  <div class="front-promo promo--f5 bg--lgray">
    <div class="align-container">
      <div class="align-container__item" style="padding-bottom: 70px;">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-10 offset-xl-1 text-center">
              <h1 class="top-50" i18n>Find a <span class="color--green">custom</span> supplier for your <span class="color--green">critical</span> business, <br>
                risk <span class="color--green">free</span>.</h1>
              <p class="front-promo__subtitle top-30" i18n>
                <b>AI-powered</b> sourcing list<br>
                <b>Expert-led</b> factory audit
              </p>
              <a class="button button--filled top-50" routerLink="/supplier-search/choice">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                <span style="font-size:24px" i18n>Search now</span>
                <svg class="icon">
                  <use xlink:href="#arrow"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--dgray" style="padding-bottom: 70px;">
    <img class="section--bg t50 r0" src="assets/testimonials-bg.png" alt="map"/>
    <div class="container">
      <div class="row bottom-70">
        <div class="col-12 ">
          <div class="heading heading--white"><span class="heading__pre-title" i18n>How we deliver</span>
            <h3 class="heading__title" i18n>Supplier <span class="color--green">SEARCH</span> engine</h3><span class="heading__layout layout--dgray">How we deliver</span>
          </div>
        </div>
      </div>
      <div class="row offset-50">
        <div class="col-md-5 col-xl-4">
          <div class="icon-item icon-item--white">
            <div class="icon-item__count"><span i18n>Source</span></div>
            <h6 class="icon-item__title" i18n>Source from the origin only</h6>
            <p class="icon-item__text" i18n>
              - Open data screening<br/>
              - Trade association membership directory<br/>
              - Global transaction record<br/>
            </p>
          </div>
        </div>
        <div class="col-md-5 col-xl-4">
          <div class="icon-item icon-item--white">
            <div class="icon-item__count"><span i18n>Filter</span></div>
            <h6 class="icon-item__title" i18n>Filter like an industrial buyer</h6>
            <p class="icon-item__text" i18n>
              - Certifications<br/>
              - Customer references<br/>
              - Competitive edge<br/>
            </p>
          </div>
        </div>
        <div class="col-md-5 col-xl-4">
          <div class="icon-item icon-item--white">
            <div class="icon-item__count"><span i18n>Rank</span></div>
            <h6 class="icon-item__title" i18n>Rank through matching rate</h6>
            <p class="icon-item__text" i18n>
              - Pre-defined threshold<br/>
              - Capped total number<br/>
            </p>
          </div>
        </div>
      </div>
      <div class="row bottom-70">
        <div class="col-12"><video class="top-50" style="width: 100%" [muted]="true" autoplay playsinline loop>
          <source src="assets/videos/supplier_search_20211101.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video></div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgray testimonials testimonials--style-2">
    <img class="section--bg t50 r0" src="assets/testimonials-bg.png" alt="map" />
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="heading"><span class="heading__pre-title" i18n>Testimonials</span>
            <h3 class="heading__title" i18n>Hear, hear</h3>
          </div>
        </div>
        <div class="col-lg-1 cursor_pointer"(click)="testimonialModalPrevClick()"><mat-icon>navigate_before</mat-icon></div>
        <div class="col-lg-7">
          <ngx-slick-carousel class="carousel" #testimonialModal="slick-carousel" [config]="testimonialConfig">
            <div ngxSlickItem *ngFor="let slide of testimonials" class="slide">
              <div class="testimonials-slider">
                <div class="testimonials-slider__item">
                  <p>{{slide.text}}</p>
                  <div class="testimonials-slider__author">
                    <h6>{{slide.author}}</h6><span></span>
                  </div>
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="col-lg-1 cursor_pointer" (click)="testimonialModalNextClick()"><mat-icon>navigate_next</mat-icon></div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <section class="section bg--dgray">
    <img class="section--bg t50 r0" src="assets/testimonials-bg.png" alt="map"/>
    <div class="container">
      <div class="row bottom-70">
        <div class="col-12">
          <div class="heading heading--white"><span class="heading__pre-title" i18n>How we deliver</span>
            <h3 class="heading__title" i18n>Expert <span class="color--green">ON-SITE</span> mission</h3>
            <span class="heading__layout layout--dgray" i18n>How we deliver</span>
          </div>
        </div>
      </div>
      <div class="row offset-50">
        <div class="col-md-5 col-xl-4">
          <div class="icon-item icon-item--white">
            <div class="icon-item__count"><span i18n>Find</span></div>
            <h6 class="icon-item__title" i18n>Find the best fit for your need</h6>
            <p class="icon-item__text" i18n>
              - Our own talent pool<br/>
              - "Mouth to ear" referral by members<br/>
              - Professional associations and syndicates<br/>
            </p>
          </div>
        </div>
        <div class="col-md-5 col-xl-4">
          <div class="icon-item icon-item--white">
            <div class="icon-item__count"><span i18n>Assist</span></div>
            <h6 class="icon-item__title" i18n>Assist you on missions from A to Z</h6>
            <p class="icon-item__text" i18n>
              - Quality system audit<br/>
              - Social compliance audit<br/>
              - Good manufacturing practices (GMP) audit<br/>
            </p>
          </div>
        </div>
        <div class="col-md-5 col-xl-4">
          <div class="icon-item icon-item--white">
            <div class="icon-item__count"><span i18n>Commit</span></div>
            <h6 class="icon-item__title" i18n>Commit to results with full insight</h6>
            <p class="icon-item__text" i18n>
              - 0 business trips required<br/>
              - SaaS for your efficiency<br/>
              - Satisfy or Free policy<br/>
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <video class="top-50" style="width: 100%" [muted]="true" autoplay playsinline loop>
            <source src="assets/videos/send_a_mission_20211101.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgray" style="padding: 50px 0;">
    <div class="container">
      <div class="row bottom-70">
        <div class="col-12">
          <div class="heading heading--center"><span class="heading__pre-title" i18n>Newsletter</span>
            <h3 class="heading__title" i18n>Get our lastest news</h3>
            <span class="heading__layout layout--lgray" i18n>Newsletter</span>
          </div>
        </div>
      </div>
     <form [formGroup]="form">
        <div class="row">
          <div class="col-6" style="padding-right: 0px;">
            <mat-form-field appearance="fill" style="float: right;">
              <mat-label for="email" i18n>Enter your email</mat-label>
              <input matInput placeholder="Email" formControlName="email" class="form-control"
                [ngClass]="{ 'is-invalid': loading && f.email.errors }">
              <mat-error *ngIf="f.email.hasError('email')" i18n>
                Please provide a valid email address
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6" style="padding-left: 0px;">
            <div type="submit" class="button button--filled" style="float: left; height : 70px; padding-top: 10px;" (click)="onSubmit()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              <span i18n>Subscribe</span>
              <svg class="icon">
                <use xlink:href="#arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section  bg--dgray" style ="padding-top: 70px; background: url('assets/home_expert.jfif') no-repeat center center;" title="Factory audit"><!--img class="section--bg t50 r0" src="assets/testimonials-bg.png" alt="map"/-->
    <div class="container">
      <div class="row bottom-70">
        <div class="col-12">
          <div class="heading heading--center"><span class="heading__pre-title" i18n>Partners</span>
            <h3 class="heading__title color--green" i18n>They Trust us</h3><span class="heading__layout layout--lgray"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
            <div class="logos-slider slick-initialized slick-slider">
              <div class="slick-list draggable">
                <div class="slick-track" style="opacity: 1; width: 100%; transform: translate3d(0px, 0px, 0px);">
                  <div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style="width: 270px;">
                    <div>
                      <div class="logos-slider__item" style="width: 100%; display: inline-block;"><img src="assets/logos/aura.png" alt="LOGO La région Auvergne-Rhône-Alpes"></div>
                    </div>
                  </div>
                  <div class="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style="width: 270px;">
                    <div>
                      <div class="logos-slider__item" style="width: 100%; display: inline-block;"><img src="assets/logos/bnc.png" alt="LOGO Banque Nationale"></div>
                    </div>
                  </div>
                  <div class="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style="width: 270px;">
                    <div>
                      <div class="logos-slider__item" style="width: 100%; display: inline-block;"><img src="assets/logos/bpi.png" alt="LOGO BPI France"></div>
                    </div>
                  </div>
                  <div class="slick-slide slick-active" data-slick-index="1" aria-hidden="false" style="width: 270px;">
                    <div>
                      <div class="logos-slider__item" style="width: 100%; display: inline-block;"><img src="assets/logos/hec.jpg" alt="LOGO HEC Montréal"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="logos-slider__dots"></div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>