<h5 mat-dialog-title>Counter offer</h5>

<mat-dialog-content [formGroup]="form" (ngSubmit)="onSubmit()">

  <mat-form-field appearance="fill">
    <mat-label for="budget">Budget</mat-label>
    <input matInput formControlName="budget" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form.controls.budget.errors }">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label for="currency">Currency</mat-label>
    <mat-select formControlName="currency" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && form.controls.currency.errors }">
      <mat-option *ngFor="let currency of currencyList" [value]="currency">
        {{currency}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="row">
    <div class="col-6">
      <div class="button button--filled right-10" (click)="onSubmit()" type="submit">Submit</div>
    </div>
    <div class="col-6">
      <div class="button button--out" (click)="cancel()" type="submit">Cancel</div>
    </div>
  </div>
</mat-dialog-actions>