import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RejectionReason } from '../../_models/mission';
import { CURRENCY } from '../../_models/currency';

@Component({
  selector: 'app-dialog-counter-offer',
  templateUrl: './dialog-counter-offer.component.html',
  styleUrls: ['./dialog-counter-offer.component.css']
})
export class DialogCounterOfferComponent implements OnInit {

  form : FormGroup;
  submitted = false;
  currencyList: string[] = [];

  // Récupérer les enum de form
  rejectionReason = RejectionReason;
  rejectionReasonKeys =  Object.keys(this.rejectionReason);

  constructor(
    private builder: FormBuilder,
    private dialogRef: MatDialogRef<DialogCounterOfferComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.builder.group({
      budget: ['', Validators.required],
      currency: ['', Validators.required]
    });

    // List de currency
    CURRENCY.forEach(objet => {
      if (objet.currency_name) {
        this.currencyList.push(objet.currency_name);
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  cancel() : void {
    // Close the dialog without sending data
    this.dialogRef.close();
  }

}
