<main class="main mobile-top">
  <!-- section start-->
  <section class="hero-block">
    <picture>
      <source srcset="assets/contact_us.jfif" media="(min-width: 992px)" /><img class="img--bg"
        src="assets/contact_us.jfif" alt="Factory" />
    </picture>
    <div class="hero-block__layout"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="align-container">
            <div class="align-container__item"><span class="hero-block__overlay">Aska</span>
              <h1 class="hero-block__title" i18n>Contact us</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!--section start-->
  <section class="section contacts pb-0"><img class="contacts__bg" src="assets/contacts-map.png" alt="map" />
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h5 class="contacts__title bottom-20" i18n>France</h5>
          <div class="contacts-item">
            <div class="contacts-item__img">
              <svg class="icon">
                <use xlink:href="#pin"></use>
              </svg>
            </div>
            <div class="contacts-item__details"><span>43 Rue Waldeck Rousseau, 69006 Lyon</span></div>
          </div>
          <div class="contacts-item">
            <div class="contacts-item__img">
              <svg class="icon">
                <use xlink:href="#phone"></use>
              </svg>
            </div>
            <div class="contacts-item__details"><a class="contacts-item__link" href="tel:+22628908002">+ 33 6 78 15 87
                18</a></div>
          </div>
          <h5 class="contacts__title bottom-20" i18n>Canada</h5>
          <div class="contacts-item">
            <div class="contacts-item__img">
              <svg class="icon">
                <use xlink:href="#pin"></use>
              </svg>
            </div>
            <div class="contacts-item__details"><span>3500-800 rue du Square-Victoria, Montréal H4Z 1A1</span></div>
          </div>
          <div class="contacts-item">
            <div class="contacts-item__img">
              <svg class="icon">
                <use xlink:href="#phone"></use>
              </svg>
            </div>
            <div class="contacts-item__details"><a class="contacts-item__link" href="tel:+22628908002">+1 438 372 8658</a></div>
          </div>
          <h5 class="contacts__title bottom-20" i18n>Online</h5>
          <div class="contacts-item">
            <div class="contacts-item__img">
              <svg class="icon">
                <use xlink:href="#mail"></use>
              </svg>
            </div>
            <div class="contacts-item__details"><a class="contacts-item__link"
                href="mailto:transporteriumus@gmail.com">contact@askafox.com</a></div>
          </div>
          <div class="contacts-item">
            <div class="contacts-item__img">
              <svg class="icon">
                <use xlink:href="#share"></use>
              </svg>
            </div>
            <div class="contacts-item__details">
              <ul class="socials socials--dark list--reset">
                <li class="socials__item"><a class="socials__link" href="https://www.linkedin.com/in/hanyin-chen" target="_blank" title="LinkedIN">
                  <svg class="icon">
                    <use xlink:href="#linkedin"></use>
                  </svg></a></li>
                <li class="socials__item"><a class="socials__link" href="https://twitter.com/HanyinChen" target="_blank" title="Twitter">
                    <svg class="icon">
                      <use xlink:href="#twitter"></use>
                    </svg></a></li>
                <li class="socials__item"><a class="socials__link" href="https://askafox.medium.com" target="_blank" title="Medium">
                  <svg class="icon">
                    <use xlink:href="#medium"></use>
                  </svg></a></li>
                <li class="socials__item"><a class="socials__link" href="https://askafox.substack.com" target="_blank" title="Substack">
                    <svg class="icon">
                      <use xlink:href="#substack"></use>
                    </svg></a></li>
              </ul>
            </div>
          </div>
        </div>
        <!---->
        <div class="col-lg-6">
          <div class="contacts-map" id="map" data-api-key="AIzaSyD5ES8GFHrarPhIVpDhFDea6fPtga0Wy4Y"
            data-marker="assets/placeholder.png"></div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section">
    <div class="container">
      <div class="row flex-column-reverse flex-lg-row">

        <div class="col-lg-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12">
                <h5 class="contact-form__subtitle" i18n>How can we help ?</h5>
              </div>
              <div class="col-md-2">
                <mat-form-field appearance="fill">
                  <mat-label for="gender" i18n>Gender</mat-label>
                  <mat-select formControlName="gender" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                    <mat-option *ngFor="let gender_element of genders" [value]="gender_element.value">
                      {{gender_element.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label for="first_name" i18n>First Name</mat-label>
                  <input matInput placeholder="First Name" formControlName="first_name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"/>
                  <mat-error *ngIf="form.controls.first_name.hasError('required')">
                    The field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label for="last_name" i18n>Last Name</mat-label>
                  <input matInput placeholder="Last Name" formControlName="last_name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"/>
                  <mat-error *ngIf="form.controls.last_name.hasError('required')">
                    The field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="email" i18n>Email</mat-label>
                  <input matInput placeholder="Email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
                  <mat-error *ngIf="form.controls.email.hasError('required')">
                    The field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="job_function" i18n>Job Function</mat-label>
                  <mat-select formControlName="job_function" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.job_function.errors }">
                    <mat-option *ngFor="let jobFunctionKey of jobFunctionKeys" [value]="jobFunctionKey">
                      {{jobFunction[jobFunctionKey]}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="job_level" i18n>Job Level</mat-label>
                  <mat-select formControlName="job_level" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.job_level.errors }">
                    <mat-option *ngFor="let jobLevelKey of jobLevelKeys" [value]="jobLevelKey">
                      {{jobLevel[jobLevelKey]}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="fill">
                  <mat-label for="company_name" i18n>Company name</mat-label>
                  <input matInput placeholder="Company name" formControlName="company_name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }"/>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="fill">
                  <mat-label for="industry" i18n>Industry</mat-label>
                  <mat-select formControlName="industry" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.industry.errors }">
                    <mat-option *ngFor="let industryKey of industryKeys" [value]="industryKey">
                      {{industry[industryKey]}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="fill">
                  <mat-label for="region" i18n>Region</mat-label>
                  <mat-select formControlName="region" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.region.errors }">
                    <mat-option *ngFor="let regionKey of regionKeys" [value]="regionKey">
                      {{region[regionKey]}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field appearance="fill">
                  <mat-label for="country_calling_code" i18n>Country code</mat-label>
                  <input matInput placeholder="Country code" size="3" maxLength="3"
                    formControlName="country_calling_code" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.country_calling_code.errors }"/>
                  <mat-error *ngIf="form.controls.country_calling_code.hasError('required')" i18n>
                    The field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field appearance="fill">
                  <mat-label for="phone_number" i18n>Phone number</mat-label>
                  <input matInput placeholder="Phone number" formControlName="phone_number" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"/>
                  <mat-icon matSuffix>phone</mat-icon>
                  <mat-error *ngIf="form.controls.phone_number.hasError('required')" i18n>
                    The field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="fill">
                  <mat-label for="message" i18n>Your message</mat-label>
                  <textarea class="form__message message--large" formControlName="message" matInput></textarea>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-checkbox formControlName="receive_news" class></mat-checkbox>
                <mat-label for="receive_news" class="form__label-text" i18n>I want to hear news from ASKaFOX.</mat-label>
              </div>
              <!--div class="col-12">
                <mat-checkbox formControlName="terms_accept"></mat-checkbox>
                <mat-label for="terms_accept" class="form__label-text">I have read and agree to the General Terms and
                  Privacy Policy.</mat-label>
              </div-->
              <div class="col-12 top-40">
                <button type="submit" class="button button--green" type="submit" [disabled]="loading">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  <span i18n>Send message</span>
                  <svg class="icon">
                    <use xlink:href="#arrow"></use>
                  </svg>
                </button>
              </div>
              <!--div class="col-12">
                <div class="alert alert--success alert--filled">
                  <div class="alert__icon">
                    <svg class="icon">
                      <use xlink:href="#check"></use>
                    </svg>
                  </div>
                  <p class="alert__text"><strong>Well done!</strong> Your form has been sent</p><span class="alert__close">
                    <svg class="icon">
                      <use xlink:href="#close"></use>
                    </svg></span>
                </div>
                <div class="alert alert--error alert--filled">
                  <div class="alert__icon">
                    <svg class="icon">
                      <use xlink:href="#close"></use>
                    </svg>
                  </div>
                  <p class="alert__text"><strong>Oh snap!</strong> Your form has not been sent</p><span class="alert__close">
                    <svg class="icon">
                      <use xlink:href="#close"></use>
                    </svg></span>
                </div>
              </div-->
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <div>
    <app-calendly-widget></app-calendly-widget>
  </div>
</main>